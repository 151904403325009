import { OktaAuth } from '@okta/okta-auth-js';

const configDev = {
  issuer: 'https://auth-test.slu.edu/oauth2/default',
  clientId: '0oa73bmp9tlqVwMNl0x7',
  redirectUri: '/login/callback',
  scopes: ['openid', 'profile', 'email'],
  postLogoutRedirectUri: 'http://localhost:3000/',
};

const configProd = {
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: '/login/callback',
  scopes: ['openid', 'profile', 'email'],
  postLogoutRedirectUri: process.env.REACT_APP_OKTA_POST_LOGOUT_RED,
}


const isProduction = process.env.NODE_ENV === 'production';

// Select the appropriate configuration
const selectedConfig = isProduction ? configProd : configDev;

const oktaAuth = new OktaAuth(selectedConfig);

export default oktaAuth;