import { useOktaAuth } from "@okta/okta-react";
import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";
import Logo from "../img/slu-2-centered-white-rgb.png";


const pages = [
  {
    text: "Sign Out",
    action: async ({ logout }) => logout(),
    condition: ({ authState: { isAuthenticated } }) => isAuthenticated,
  },
  {
    text: "Sign In",
    action: async ({ login }) => login(),
    condition: ({ authState: { isAuthenticated, isPending } }) =>
      !isPending && !isAuthenticated,
  },
];

const styles = {
  linkButton: {
    my: 2,
    color: "white",
    display: "block",
    fontSize: "12.6px",
  },
};

export default function NavBar() {
  const oktaState = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);

  if (!oktaState.authState) {
    return null;
  }

  oktaState.login = async () => oktaState.oktaAuth.signInWithRedirect();
  oktaState.logout = async () => oktaState.oktaAuth.signOut();
  // Add a dependency on the oktaState to ensure approver status is checked on changes

  return (
    <AppBar
      position="static"
      enableColorOnDark
      sx={{ backgroundColor: "#003366", boxShadow: "none", height: "64px" }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{ fill: "#fff", height: "54px", my: 0.5, display: "flex" }}
            component="a"
            title="Saint Louis University SLU Logo"
          >
            <img
              className="logo"
              src={Logo}
              alt="Saint Louis University"
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "contain", // Maintain aspect ratio and fit inside the box
              }}
            />
          </Box>
          <div className="title">
            <p>SLU Madrid Requisitions</p>
          </div>

          <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "end" }}>
            {pages.reduce((filtered, { text, link, action, condition }) => {
              if (typeof condition === "undefined" || !!condition(oktaState)) {
                const item = (
                  <Button
                    key={text}
                    component={action ? "div" : Link}
                    to={link}
                    onClick={() => action && action(oktaState)}
                    sx={styles.linkButton}
                  >
                    {text}
                  </Button>
                );
                filtered.push(item);
              }
              return filtered;
            }, [])}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
