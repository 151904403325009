import React, { useState, useEffect } from "react";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import styleded from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";
import CheckIcon from "@mui/icons-material/Check";
import { useOktaAuth } from "@okta/okta-react";
import Box from "@mui/material/Box";
import { getApprovalHistory, getRequisitionStatus } from "../api/api";
function extractDateFromTimestamp(timestamp) {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const day = String(date.getDate()).padStart(2, "0");

  return `${day}-${month}-${year}`;
}
const euroFormatter = new Intl.NumberFormat("en-EU", {
  style: "currency",
  currency: "EUR",
});

const InputContainer = styleded.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40em;
    margin: 1%;
    `;

const VISIBLE_FIELDS = [
  "Requisition ID",
  "Date",
  "Requester",
  "Description",
  "Vendor",
  "Amount",
  "VAT Included",
  "Status",
];
const columns = VISIBLE_FIELDS.map((field) => {
  let columnConfig = {
    field,
    headerName: field,
    renderCell: (params) => {
      if (field === "Status") {
        return <span dangerouslySetInnerHTML={{ __html: params.value }} />;
      } else if (field === "VAT Included") {
        // Render CheckIcon for true and nothing for false
        return params.value ? <CheckIcon /> : null;
      }
      if (window.innerWidth <= 600) {
        columnConfig.minWidth = 400;
      }

      return params.value;
    },
  };

  // Apply flex: 1 only if the field is 'Description'
  if (field === "Description") {
    columnConfig.flex = 1;
  }
  if (window.innerWidth <= 600) {
    columnConfig.minWidth = 400;
  }

  return columnConfig;
});

export default function HistoryDisplay({ accessToken }) {
  const { authState } = useOktaAuth();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (authState?.isAuthenticated) {
      const loadTable = async () => {
        setLoading(true);

        try {
          const responseData = await getApprovalHistory(accessToken);
          const totalRows = responseData?.data || [];

          const formattedRows = totalRows.map((row) => {
            return {
              id: row?.["recordID"] || "N/A",
              "Requisition ID": row?.["requisitionID"] || "N/A",
              Date: extractDateFromTimestamp(row?.["dateCreated"] || "N/A"),
              Description: row?.["description"] || "N/A",
              Vendor: row?.["vendor"] || "N/A",
              Amount: euroFormatter.format(row?.["amount"] || 0),
              "VAT Included": row?.["vat"] ?? false,
              Requester: row?.["requester"] || "N/A",
              Status: row?.["status"] || "Unknown",
            };
          });

          setTableData(formattedRows);

          setLoading(false);
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      };

      loadTable();
    }
  }, [accessToken]);
  return (
    <div>
      <div style={{ width: "100%" }}>
        <Box
          sx={{
            height: 400,
            width: "fitContent",
            margin: "1%",
            boxShadow: "0 0 20px rgba(0, 0, 0, 0.15)",
          }}
        >
          <DataGrid
            rows={tableData}
            localeText={{
              noRowsLabel: loading ? (
                <CircularProgress />
              ) : (
                "No Requisitions Submitted"
              ),
            }}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10, 20, 50]}
            getRowHeight={() => "auto"}
            sx={{
              [`& .${gridClasses.cell}`]: {
                py: 1,
              },
            }}
          />
        </Box>
      </div>
    </div>
  );
}
